.personnelHolography-detail[data-v-2cd2966b] {
  width: 100%;
  height: 100%;
}
.personnelHolography-detail[data-v-2cd2966b] .el-tabs {
  width: 100%;
  height: 100%;
}
.personnelHolography-detail[data-v-2cd2966b] .el-tabs__header.is-left {
  min-width: 130px !important;
}
.personnelHolography-detail[data-v-2cd2966b] .el-tabs--border-card {
  background-color: #f5f7fa;
}
.personnelHolography-detail[data-v-2cd2966b] .el-tabs__content {
  height: 100%;
  background-color: #fff;
}
.personnelHolography-detail[data-v-2cd2966b] .el-tabs__content ::v-deep .el-tab-pane {
  height: 100% !important;
}
.personnelHolography-detail .formContentBox[data-v-2cd2966b] {
  padding: 12px 0;
  height: calc(100% - 76px);
}